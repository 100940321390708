import React, {useState} from 'react';
import {Box, Button, CircularProgress, Container, FormControl, Grid, Link, TextField, Typography} from '@mui/material';
import {AuthService} from 'services/index';

const initFormState = {
    nameError: false,
    passError: false,
    messageError: false
};

function Support() {
    const authService = new AuthService();
    const [supportForm, setSupportForm] = useState({
        name: '',
        email: '',
        message: '',
        sending: false,
        errors: []
    });
    const [supportFormState, setSupportFormState] = useState(initFormState);

    const doValidateForm = () => {
        const {
            name,
            email,
            message
        } = supportForm;

        const errors = {...initFormState};

        if (name === '') errors.nameError = true;
        if (email === '') errors.emailError = true;
        if (message === '') errors.messageError = true;

        // If at least one of errors is true - set false state
        const state = !Object.values(errors).some((error) => error);

        setSupportFormState({
            ...supportFormState,
            ...errors
        });

        return state;
    }

    const sendSupportForm = async () => {
        const isValidForm = doValidateForm();
        if (!isValidForm) {
            setSupportForm({...supportForm, sending: false, errors: []});
            return;
        }
/*        const payload = supportForm;
        const result = await authService.sendSupportForm(payload);

        if (result.code === 200) {
            snackActions.success(globalDictionary.get('support_form_success', language));
            setSupportForm({
                name: '',
                email: '',
                message: '',
                errors: [],
                sending: false
            });
        } else {
            setSupportForm({
                ...supportForm,
                errors: result.errors,
                sending: false
            });
        }*/
    }

    return (
        <Container sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Typography variant={'h4'} component={'h1'} fontWeight={'500'} align={'center'} sx={{
                marginTop: '15px'
            }}>
                Поддержка
            </Typography>
            <Grid container sx={{
                height: '100%'
            }}>
                <Grid item md={12} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '15px'
                }}>
                    <Link href={'mailto:support@publisher.com'} underline={'none'} color={'secondary'}
                          fontSize={'1.5em'}>support@publisher.com</Link>
                </Grid>
                {/*<Grid item md={12} sx={{*/}
                {/*    display: 'flex',*/}
                {/*    justifyContent: 'center',*/}
                {/*    alignItems: 'center',*/}
                {/*    flexDirection: 'column',*/}
                {/*    gap: '15px'*/}
                {/*}}>*/}
                {/*    <Box sx={{maxWidth: 600, padding: "20px 5px", margin: "0 auto"}}>*/}
                {/*        <Typography gutterBottom variant="h5" align={'center'}>*/}
                {/*            Форма обратной связи*/}
                {/*        </Typography>*/}
                {/*        <FormControl sx={{mt: 3}} variant="outlined">*/}
                {/*            <Grid container spacing={1}>*/}
                {/*                <Grid item xs={12}>*/}
                {/*                    <TextField*/}
                {/*                        placeholder={'Введите ФИО'}*/}
                {/*                        label={'ФИО'}*/}
                {/*                        variant="outlined"*/}
                {/*                        fullWidth*/}
                {/*                        required*/}
                {/*                        error={supportFormState.nameError}*/}
                {/*                        onChange={(e) => setSupportForm({...supportForm, name: e.target.value})}*/}
                {/*                        value={supportForm.name}*/}
                {/*                    />*/}
                {/*                </Grid>*/}
                {/*                <Grid item xs={12}>*/}
                {/*                    <TextField*/}
                {/*                        type="email"*/}
                {/*                        placeholder={'Введите email'}*/}
                {/*                        label={'Email'}*/}
                {/*                        variant="outlined"*/}
                {/*                        fullWidth*/}
                {/*                        required*/}
                {/*                        error={supportFormState.emailError}*/}
                {/*                        onChange={(e) => setSupportForm({...supportForm, email: e.target.value})}*/}
                {/*                        value={supportForm.email}*/}
                {/*                    />*/}
                {/*                </Grid>*/}
                {/*                <Grid item xs={12}>*/}
                {/*                    <TextField*/}
                {/*                        placeholder={'Введите сообщение'}*/}
                {/*                        label={'Сообщение'}*/}
                {/*                        variant="outlined"*/}
                {/*                        fullWidth*/}
                {/*                        required*/}
                {/*                        error={supportFormState.messageError}*/}
                {/*                        onChange={(e) => setSupportForm({...supportForm, message: e.target.value})}*/}
                {/*                        value={supportForm.message}*/}
                {/*                        multiline={true}*/}
                {/*                        rows={7}*/}
                {/*                    />*/}
                {/*                </Grid>*/}
                {/*                <Grid item xs={12} display={'flex'} justifyContent={'center'}>*/}
                {/*                    {supportForm.sending ? (*/}
                {/*                        <CircularProgress color={'secondary'}/>*/}
                {/*                    ) : (*/}
                {/*                        <Button*/}
                {/*                            size="large"*/}
                {/*                            type="submit"*/}
                {/*                            variant="contained"*/}
                {/*                            fullWidth*/}
                {/*                            color="primary"*/}
                {/*                            onClick={(e) => {*/}
                {/*                                e.preventDefault();*/}

                {/*                                setSupportForm({*/}
                {/*                                    ...supportForm,*/}
                {/*                                    sending: true*/}
                {/*                                });*/}

                {/*                                setTimeout(async () => {*/}
                {/*                                    await sendSupportForm();*/}
                {/*                                }, 500);*/}
                {/*                            }}*/}
                {/*                        >*/}
                {/*                            Отправить*/}
                {/*                        </Button>*/}
                {/*                    )}*/}
                {/*                </Grid>*/}
                {/*            </Grid>*/}
                {/*        </FormControl>*/}
                {/*    </Box>*/}
                {/*</Grid>*/}
            </Grid>
        </Container>
    );
}

export default Support;
