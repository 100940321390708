import React, {useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import SamplesHeader from './components/SamplesHeader';
import SamplesFilter from './components/SamplesFilter';
import SamplesProductList from './components/SamplesProductList';
import SamplesTable from "./components/SamplesTable";
import {Box, Container} from "@mui/material";
import {StoreContext} from "App";
import Grid from "@mui/material/Unstable_Grid2";
import {ActivityIndicator} from "components";
import SamplesProductItem from "./components/SamplesProductItem";


const SampleList = observer(() => {
    const [samples, setSamples] = useState({
        data: [],
        totalSamples: 0,
        loading: true,
    });
    const [products, setProducts] = useState({
        data: [],
        loading: true,
    });
    const [selectedProduct, setSelectedProduct] = React.useState(null);
    const [selectedSamples, setSelectedSamples] = useState([]);
    const {samples: samplesStore, projects, products: productsStore} = useContext(StoreContext);

    useEffect(() => {
        (async () => {
            await getSamples();
            await getProducts();
        })();
    }, [projects?.activeProject]);

    useEffect(() => {
        if (selectedProduct) getSamples({product_id: selectedProduct._id});
    }, [selectedProduct]);

    const handleSelectedSamplesChange = (samples) => {
        setSelectedSamples(samples);
    };

    const changeSelectedProduct = (product) => {
        setSelectedProduct(product);
    }

    const getProducts = async (filter) => {
        if (!projects?.activeProject?._id) return;
        setProducts({...products, loading: true});

        const filters = {
            ...filter,
            project_id: projects?.activeProject?._id,
        }
        const result = await productsStore.getProducts(filters);

        setTimeout(() => {
            setProducts({
                data: result?.products || [],
                loading: false,
            });
        }, 500);
    }

    const getSamples = async (filter = {}) => {
        if (!projects?.activeProject?._id) return;

        setSamples({...samples, loading: true});

        const filters = {
            ...filter,
            project_id: projects?.activeProject?._id,
        }

        if (filter.product_id) filters.product_id = filter.product_id;

        const result = await samplesStore.getSamples(filters);

        setTimeout(() => {
            setSamples({
                data: result?.samples || [],
                totalSamples: result?.totalSamples || 0,
                loading: false,
            });
            setSelectedSamples([]);
        }, 500);
    }

    if (products.loading && samples.loading) return <ActivityIndicator/>;

    return (
        <Container sx={{
            mt: 2,
        }}>
            <SamplesHeader data={samples}/>
            <Grid container sx={{
                height: '100%',
            }}>
                <Grid item xs={12} sm={12} md={3} lg={3} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    {
                        selectedProduct ? (
                            <SamplesProductItem product={selectedProduct}
                                                changeSelectedProduct={changeSelectedProduct}/>
                        ) : (
                            <SamplesProductList products={products.data} changeSelectedProduct={changeSelectedProduct}/>
                        )
                    }
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9}>
                    <Box sx={{
                        mt: 3,
                    }}>
                        <SamplesFilter data={samples.data} getSamples={getSamples} selectedSamples={selectedSamples}/>
                    </Box>
                    <Box sx={{
                        mt: 2,
                    }}>
                        {
                            samples.loading ? <ActivityIndicator/> : (
                                <SamplesTable data={samples.data}
                                              getSamples={getSamples}
                                              handleSelectedSamplesChange={handleSelectedSamplesChange}
                                />
                            )
                        }
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
});

export default SampleList;
